<template>
  <div>
    <div class="row">
      <div class="col-xl-3">
        <!--begin::Stats Widget 29-->
        <div
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(/media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::Body-->
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <span class="svg-icon svg-icon-2x">
                <inline-svg src="/media/svg/icons/Shopping/Wallet.svg" />
              </span>
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
            >
              ฿
              {{
                cash.baht.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">
              เงินในกระเป๋า
            </span>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 29-->
      </div>
    </div>
    <b-form class="form" @submit.stop.prevent="do_save">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="text-dark-75 font-weight-bold mb-8">ถอนเงิน</h3>
          <div class="row" v-if="form.bank">
            <div class="col-lg-8">
              <div class="col-md-6 offset-sm-3 col-lg-9">
                <label class="option">
                  <span class="option-label">
                    <span class="option-head justify-content-start">
                      <div class="symbol symbol-40 flex-shrink-0 mr-4">
                        <div
                          class="symbol-label"
                          :style="
                            `background-image: url('${BANK_URL}${form.bank.bank_img}')`
                          "
                        ></div>
                      </div>
                      <span class="option-title">{{
                        form.bank.bank_name
                      }}</span>
                    </span>
                    <span class="option-body">
                      <div class="mb-1" style="font-size: 1.1rem">
                        เลขบัญชี {{ form.bank.account_no }}
                      </div>
                      <div style="font-size: 1.1rem">
                        ชื่อบัญชี {{ form.bank.account_name }}
                      </div>
                    </span>
                  </span>
                </label>
                <div class="fv-plugins-message-container"></div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-8">
              <b-form-group label-cols="3" abel-cols-sm="3" label-cols-lg="3">
                <router-link :to="`/profile?cat=3`">
                  <b-button>จัดการบัญชีธนาคาร</b-button>
                </router-link>
              </b-form-group>
              <b-form-group
                label-cols="3"
                label-cols-sm="3"
                label-cols-lg="3"
                label-align="right"
                content-cols="8"
                content-cols-sm="5"
                description="เลือกบัญชีธนาคาร"
                label="บัญชีที่รับเงิน"
                label-for="input-bank"
              >
                <b-form-select
                  id="input-bank"
                  v-model="form.bank"
                  :options="[
                    {
                      value: '',
                      text: 'กรุณาเลือกบัญชีธนาคาร',
                      disabled: true
                    },
                    ...bankAccounts.records.map(x => ({
                      value: x,
                      text: `${getBankName(x.bank_name)} ${x.account_no}`
                    }))
                  ]"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols="3"
                label-cols-sm="3"
                label-cols-lg="3"
                label-align="right"
                label="จำนวนเงิน"
                label-for="input-amount"
                content-cols="8"
                content-cols-sm="5"
              >
                <b-form-input
                  id="input-amount"
                  v-model="form.amount"
                  type="number"
                  step="0.01"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                class="btn btn-success mr-2"
                type="submit"
                style="width: 144px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                ถอนเงินเลย
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CREATE_WITHDRAW } from "@/graphql/withdraw";
import { GET_PROFILE_BANK, GET_CASH } from "@/graphql/profile";
import { BANK_URL, getBankName } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/wallet" },
      { title: `ถอนเงิน` }
    ]);
  },
  data() {
    return {
      BANK_URL,
      valid: false,
      form: {
        amount: 0,
        bank: ""
      },
      rules: {
        required: value => !!value || "กรุณากรอกข้อมูล"
      }
    };
  },
  validations: {
    form: {
      bank: {
        required
      },
      amount: {
        required
      }
    }
  },
  methods: {
    getBankName,
    async do_save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันการถอนเงิน ?",
        icon: "question",
        html: `กรุณาตรวจสอบ บัญชีธนาคาร ก่อนบันทึกทุกครั้ง`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        amount: Number(this.$v.form.amount.$model),
        bank: this.$v.form.bank.$model._id
      };

      const {
        data: { createWithdraw }
      } = await this.$apollo.mutate({
        mutation: CREATE_WITHDRAW,
        variables: {
          ...input
        }
      });
      if (!createWithdraw.isSuccess) {
        this.$message.error(
          createWithdraw.message ||
            (createWithdraw.error_list &&
              createWithdraw.error_list.map(x => x.message).join())
        );
      } else {
        this.$message.success(createWithdraw.message);
        this.$router.push({ name: "wallet", query: { cat: 3 } });
      }
    }
  },
  apollo: {
    cash: {
      query: GET_CASH
    },
    bankAccounts: {
      query: GET_PROFILE_BANK
    }
  }
};
</script>
